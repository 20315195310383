<template>
  <form class="unitSwitch">
    <input
      type="radio"
      :id="'radioMM' + uuid"
      class="unitSwitch__mm"
      name="inchmm"
      :checked="!isInchSearch"
      value="mm"
      @change="handleChange"
    >
    <label
      :for="'radioMM' + uuid"
      v-tv:searchResults="'MetricSearch'"
    />
    <input
      type="radio"
      :id="'radioInch' + uuid"
      class="unitSwitch__inch"
      name="inchmm"
      :checked="isInchSearch"
      value="inch"
      @change="handleChange"
    >
    <label :for="'radioInch' + uuid">{{ $tv('inch', 'unitsOfMeasure') }}</label>
  </form>
</template>

<script>
export default {
  props: {
    isInchSearch: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  methods: {
    handleChange() {
      this.$emit('changeUnit')
    }
  }
}
</script>

<style lang="scss">
.unitSwitch {
  position: absolute;
  right: 0;
  top: 0;
  border-left: .0625rem solid $color--input__border;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include breakpoint-down($md) {
    flex-direction: row;
    right: 2.4rem;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: map-get($base-text-label-small, font-size );
    font-weight: map-get($base-text-label-small,font-weight);
    color: $color__text__search;
    transition: background-color .2s ease-in-out;
    width: 2.5rem;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;

    &:hover {
      background-color: $color--btn__secondary--hover;
      color: $color__white;
    }
  }

  input {
    display: none;

    &:checked + label {
      background-color: $color--btn__secondary;
      color: $color__white;
    }
  }
}
</style>
