<template>
  <div
    id="SearchOverlayMobile"
    class="pure-form"
  >
    <SearchInputField
      :focus="focus"
      @search-blur="$emit('search-blur')"
      @search-triggered="runSearch"
      @searchTerm="getOverlay"
      :is-loading="isLoading"
      :is-inch-search="isInchSearch"
      @changeUnit="handleChange"
    />
    <div
      v-if="searchTermResult.customerSuggestions?.length > 0"
      class="overlay__suggestions"
    >
      <div
        v-for="(suggestion, index) in searchTermResult.customerSuggestions.slice(0, 4)"
        :key="index"
      >
        <div
          class="suggestion"
          v-text="'#' + suggestion.CustomerSku"
          @click="openBuyBoxSku(suggestion.Sku)"
        />
      </div>
    </div>
    <div
      v-if="searchTermResult && searchTermResult.searchResults && searchTermResult.searchResults.length"
      class="search-overlay__content"
    >
      <div class="h5">
        <span v-tv:searchResults="'AmountOfProducts'" />: <span
          v-text="searchTermResult.count"
        />
      </div>
      <NewSearchOverlayResults
        :products="mappedProducts"
        :l2categories="l2categories"
        :categories="categories"
        :search-term="searchTerm"
        :is-any-search="isAnySearch"
        @close-overlay="$emit('close-overlay')"
        @openBuyBox="openBuyBox"
        @clickItem="clickItem"
      />
    </div>
    <div
      v-else-if="searchTermResult && searchTermResult.searchResults && searchTermResult.searchResults.length === 0 && !searchTermResult.customerSuggestions?.length > 0"
      class="search-overlay__content"
    >
      <div
        class="h5"
        v-tv:searchResults="'NoProducts'"
      />
    </div>
    <div
      v-else-if="searchTermResult && !searchTermResult.customerSuggestions?.length > 0"
      class="overlay__skeleton"
    >
      <SearchSkeleton
        @close="closeOverlay()"
        :mobile="true"
      />
    </div>
  </div>
</template>

<script>
import NewSearchOverlayResults from '~/components/search/NewSearchOverlayResults.vue'
import SearchInputField from '~/components/search/SearchInputField.vue'
import {getMappedProducts} from '~/assets/js/utils/product_attributes_mapping'
import SearchSkeleton from '~/components/skeletons/SearchSkeleton.vue'

export default {
  components: {
    NewSearchOverlayResults, SearchInputField, SearchSkeleton
  },
  props: {
    focus: {
      type: Boolean
    },
    searchTerm: {
      type: String,
      default() {
        return ''
      }
    },
    l2categories: {
      type: Array,
      default () {
        return []
      }
    },
    categories: {
      type: Array,
      default () {
        return []
      }
    },
    searchTermResult: {
      type: Object,
      default () {
        return {}
      }
    },
    isLoading: {
      type: Boolean,
      default () {
        return false
      }
    },
    isInchSearch: {
      type: Boolean,
      default () {
        return false
      }
    },
    isAnySearch: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  methods: {
    runSearch() {
      this.$emit('close-overlay')
    },
    getOverlay(value) {
      this.$emit('search-term', value)
    },
    openBuyBox(product) {
      this.$emit('openBuyBox', product)
    },
    openBuyBoxSku(sku) {
      this.$emit('openBuyBoxSku', sku)
    },
    handleChange() {
      this.$emit('changeUnit')
    },
    clickItem(sku, rank) {
      this.$emit('clickItem', sku, rank)
    }
  },
  computed: {
    mappedProducts() {
      return getMappedProducts(this.searchTermResult)
    }
  }
}
</script>

<style lang="scss">
[id='SearchOverlayMobile'] {
  margin-top: -.9rem;
  position: relative;
  justify-content: center;
  align-items: center;

  .overlay__suggestions {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .suggestion {
      border-radius: .5rem;
      border: .125rem solid $color__fst_gray__100;
      padding: .25rem .5rem;
      cursor: pointer;
    }
  }

  .search-overlay__content {
    margin-top: 1rem;

    .h5 {
      margin-bottom: 1rem;
    }
  }
}

.popup__wrapper:has(#SearchOverlayMobile) {
  width: 100%;
}
</style>
