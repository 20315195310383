<template>
  <div
    id="SearchOverlayMobile"
    class="pure-form"
  >
    <SearchInputField
      :focus="focus"
      @search-blur="$emit('search-blur')"
      @search-triggered="runSearch"
      @searchTerm="getOverlay"
      :is-loading="isLoading"
      :is-inch-search="isInchSearch"
      @changeUnit="handleChange"
    />
    <div
      v-if="searchTermResult && searchTermResult.products && searchTermResult.products.length"
      class="search-overlay__content"
    >
      <div class="h5">
        <span v-tv:searchResults="'AmountOfProducts'" />: <span
          v-text="searchTermResult.count"
        />
      </div>
      <SearchOverlayResults
        :products="mappedProducts"
        :l2categories="l2categories"
        :categories="categories"
        :search-term="searchTerm"
        :is-any-search="isAnySearch"
        @close-overlay="$emit('close-overlay')"
        @openBuyBox="openBuyBox"
        @clickItem="clickItem"
      />
    </div>
    <div
      v-else
      class="search-overlay__content"
    >
      <div
        class="h5"
        v-tv:searchResults="'NoProducts'"
      />
    </div>
  </div>
</template>

<script>
import SearchOverlayResults from '~/components/search/SearchOverlayResults.vue'
import SearchInputField from '~/components/search/SearchInputField.vue'
import {getMappedProducts} from '~/assets/js/utils/product_attributes_mapping'

export default {
  components: {
    SearchOverlayResults, SearchInputField
  },
  props: {
    focus: {
      type: Boolean
    },
    searchTerm: {
      type: String,
      default() {
        return ''
      }
    },
    l2categories: {
      type: Array,
      default () {
        return []
      }
    },
    categories: {
      type: Array,
      default () {
        return []
      }
    },
    searchTermResult: {
      type: Object,
      default () {
        return {}
      }
    },
    isLoading: {
      type: Boolean,
      default () {
        return false
      }
    },
    isInchSearch: {
      type: Boolean,
      default () {
        return false
      }
    },
    isAnySearch: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  methods: {
    runSearch() {
      this.$emit('close-overlay')
    },
    getOverlay(value) {
      this.$emit('search-term', value)
    },
    openBuyBox(product) {
      this.$emit('openBuyBox', product)
    },
    handleChange() {
      this.$emit('changeUnit')
    },
    clickItem(sku, rank) {
      this.$emit('clickItem', sku, rank)
    }
  },
  computed: {
    mappedProducts() {
      return getMappedProducts(this.searchTermResult)
    }
  }
}
</script>

<style lang="scss">
[id='SearchOverlayMobile'] {
  margin-top: -.9rem;
  position: relative;
  justify-content: center;
  align-items: center;

  .search-overlay__content {
    margin-top: 1rem;

    .h5 {
      margin-bottom: 1rem;
    }
  }
}
</style>
