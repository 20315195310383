<template>
  <PopupBase
    class="popup-brands"
    v-bind="$attrs"
  >
    <div v-if="brands">
      <div class="h3 brands--header">
        {{ $tv('popupHeadline', 'brands') }}
      </div>
      <div class="popup-brands-container">
        <div
          v-for="brand in brands"
          :key="brand.id"
          class="brand__tile"
        >
          <div class="brand__headline">
            <span
              class="h6 brand__name"
              v-text="brand.Brand"
            />
            <img
              v-if="getLogoUrl(brand)"
              class="brand__logo"
              :src="brand.SmallLogo.url"
              :alt="brand.Brand"
            >
          </div>
          <Markdown
            class="brand__content"
            :text="brand.BrandDescription"
          />
        </div>
      </div>
    </div>
  </PopupBase>
</template>

<script>
import PopupBase from '~/components/popup/PopupBase.vue'

export default {
  name: 'PopupOrderDetails',
  components: {PopupBase},
  props: {
    order: {
      type: Object,
      required: false,
      default() {
        return {
        }
      }
    },
    isLoading: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    brands: {
      type: Array,
      required: false,
      default() {
        return null
      }
    }
  },
  methods: {
    getLogoUrl: function (brand) {
      // eslint-disable-next-line eqeqeq
      if (brand?.BigLogo?.url != null) {
        return brand?.SmallLogo?.url
      }
      return null
    }
  }
}
</script>

<style lang="scss">
.popup-brands {
  position: relative;

  @include breakpoint-down($md) {
    z-index: 1001;
  }

  @include breakpoint-up($md) {
    .popup__wrapper {
      max-width: 50rem;
    }
  }

  .brand__tile {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .brand__name {
      position: relative;
      vertical-align: top;
      color: $color__black;
      padding-top: 0;
    }

    .brand__logo {
      position: relative;
      padding-left: .5rem;
      height: 1.5rem;
    }

    .brand__content {
      position: relative;
      padding-top: .5rem;
    }
  }
}
</style>
