<template>
  <div
    class="important-messages"
    v-if="importantMessages.length > 0"
  >
    <Markdown
      v-for="(notification, index) in importantMessages"
      :key="index"
      :text="notification.Text"
      class="important-messages__message"
    />
  </div>
</template>
<script>
export default {
  props: {
    importantMessages: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>
<style lang="scss">
.important-messages {
  background-color: $color__text__default;
  color: $color__white;
  font-size: 1.3rem;
  padding: $default-margin-padding-width;

  &__message {
    margin-top: $default-margin-padding-width;

    &:first-child {
      margin-top: 0;

      > p {
        margin-top: 0;
      }
    }

    &:last-child {
      > p:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
