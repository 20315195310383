import axios from '~/services/axios_auth'

export const useNotificationService = () => {
  const baseurl = useRuntimeConfig().public.BASIC_API_URL  || 'https://apim-enext-stg.azure-api.net'

  const config_axios = axios.create({
    baseURL: useRuntimeConfig().public.MESSAGES_API || (baseurl + '/messages/1.0.0')
  })

  const pollMessages = async function() {
    const res = await config_axios.get('/notifications')
    return res?.data?.value ? res.data.value : []
  }

  const markAsRead = async function(notificationUuid, userUuid, companyUuid, isRead = true) {
    const body = {
      'notificationUuid': notificationUuid,
      'userUuid': userUuid,
      'customerUuid': companyUuid,
      'isRead': isRead
    }
    return config_axios.put(`/notifications('${notificationUuid}')`, body)
  }

  return {
    pollMessages,
    markAsRead
  }
}
