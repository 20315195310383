<template>
  <div class="overlayAdressSelector">
    <div
      class="overlayAdressSelector__label"
    >
      {{ $tv('selectLabel', 'addresses') }}
    </div>
    <DropDown
      :id="'dropdown'"
      :data-items="addresses"
      :data-item-key="'addressUuid'"
      :text-field="'dropDownName'"
      :value="selectedDeliveryAddress"
      @change="handleChange"
    />
  </div>
</template>
<script>
import {ADDRESS_TYPE} from '~/assets/js/constants'
import {storeToRefs} from 'pinia'
import DropDown from '~/components/input-components/DropDown'
import {useCartStore} from '@/store/cart'
export default {
  components: {DropDown},
  props: {
    addresses: {
      type: Array,
      default() {
        return []
      }
    },
    header: {
      type: String,
      default() {
        return ''
      }
    },
    headerPopup: {
      type: String,
      default() {
        return ''
      }
    },
    locked: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  setup() {
    const cartStore = useCartStore()
    const {deliveryAddressUuid} = storeToRefs(cartStore)
    const {setSelectedAddress} = cartStore
    return {
      selectedDeliveryAddressUuid: deliveryAddressUuid,
      setSelectedAddress
    }
  },
  computed: {
    selectedDeliveryAddress() {
      return this.addresses.find(a => a.addressUuid === this.selectedDeliveryAddressUuid)
    }
  },
  methods: {
    handleChange(event) {
      const address = this.addresses.find(a => a.addressUuid === event.value.addressUuid)
      this.setSelectedAddress({address: address, type: ADDRESS_TYPE.Delivery})
    }
  },
  data() {
    return {
      showAddresses: false
    }
  }
}
</script>
<style lang="scss">
.overlayAdressSelector {
  letter-spacing: 0;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint-down($md) {
    margin: 0;
  }

  .overlayAdressSelector__label {
    color: $color__white;
    margin-left: 2rem;
    margin-top: .625rem;
    float: left;
    width: 12rem;

    @include breakpoint-down($md) {
      margin: 0;
      width: 100%;
      margin-bottom: .125rem;
      text-align: center;
    }
  }

  .dropdown-wrapper {
    overflow: hidden;
    width: calc(100% - 16rem);

    @include breakpoint-down($md) {
      width: 100%;
    }
  }
}
</style>
