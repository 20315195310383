<template>
  <div
    class="menu-point-wrapper"
    :class="`${props.isBack ? 'menu-point--back' : ''} ${brandClass}`"
  >
    <div
      class="menu-point"
      :class="brandClass"
    >
      <div
        v-show="props.isBack"
        class="left"
        @click="emit('top-level')"
      >
        <NavigationChevronLeft />
      </div>
      <div
        class="middle"
      >
        <span
          v-if="props.section?.preLabel && !props.isBack"
          class="pre-label"
          v-text="props.section?.preLabel + ': '"
          @click="executeFunction()"
        />
        <a
          v-if="props.section?.externalUrl"
          :href="props.section?.externalUrl"
          :target="props.section?.externalUrl ? '_blank' : '_self'"
        >{{ label }}</a>
        <nuxt-link
          v-else-if="props.section?.localRoute && !props.isBack && (!props.section.Subsections || props.section.Subsections?.length === 0)"
          :to="localePath(props.section?.localRoute)"
          @click="emit('close-menu')"
        >
          <div>{{ label }}</div>
        </nuxt-link>
        <div
          v-else
          @click="executeFunction()"
        >
          {{ label }}
        </div>
      </div>
    </div>
    <div
      class="right"
      v-show="!props.isBack && props.section.Subsections && props.section.Subsections.length > 0"
      @click="emit('sub-level', props.section.Subsections)"
    >
      <NavigationChevronRight
        class="navigate-sub"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import NavigationChevronRight from '~/assets/icons/navigation_chevron_right.svg?component'
import NavigationChevronLeft from '~/assets/icons/navigation_chevron_left.svg?component'

export interface Props {
  section?: any,
  isBack?: boolean
}

const emit = defineEmits(['sub-level', 'top-level', 'close-menu'])

const props = withDefaults(defineProps<Props>(), {
  section: () => {},
  isBack: false
})

const label = computed(() => {
  // eslint-disable-next-line eqeqeq
  return props.isBack && props.section?.preLabel != null ? props.section?.preLabel : props.section.label
})

const brandClass = computed(() => {
  if (props.section?.brands) {
    let brandCodes = props.section?.brands.map(b => b.Code.toLowerCase()).sort()
    return ` brand-${brandCodes.join('')}`
  }
  return null
})

const executeFunction = async() => {
  if (props.section.function?.name) {
    await props.section.function(props.section.value)
  }
  if (props.isBack) {
    emit('top-level')
  } else if (props.section.Subsections && props.section.Subsections.length > 0) {
    emit('sub-level', props.section.Subsections)
  } else {
    emit('top-level')
  }
}

</script>
<style lang="scss">
.menu-point-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: .1rem solid $color__fst__light__gray;
  width: calc(100% - 3rem);
  font-size: 1.125rem;
  color: $color__primary;

  .left, .right {
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0.8rem 0 .6rem;
  }

  .middle {
    width: 100%;
    line-height: 3rem;
    display: flex;
    gap: 1rem;

    div {
      width: 100%;
      cursor: pointer;
    }
  }

  a {
    color: $color__primary;
    text-decoration: none;
    font-weight: $base-text-font-weight-semi-bold;
    width: 100%;
  }

  .icon--svg {
    cursor: pointer;
    font-size: 1.4rem;
  }

  .menu-point {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: calc(100% - 2rem);

    .pre-label {
      font-weight: $base-text-font-weight-light;
    }
  }
}
</style>
