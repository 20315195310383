<template>
  <div
    @click="handleBellClicked"
    class="notification-mobile-icon clickable"
  >
    <SocialNotifications
      :class="{ 'new-messages': unseenNotifications }"
      :title="$tv('services.bellIconTooltip', 'header')"
      class="mobile-icon notification"
    />
    <span
      v-if="unreadNotificationsLength > 0 "
      class="count"
      v-text="unreadNotificationsLength"
    />
  </div>
</template>

<script>
import {useNotificationStore} from '@/store/notification'
import {storeToRefs} from 'pinia'
import SocialNotifications from '~/assets/icons/social_notifications.svg?component'

export default defineComponent({
  components: {SocialNotifications},
  props: {
    unreadNotificationsLength: {
      type: Number,
      required: true
    }
  },
  setup() {
    const {SetAllSeen} = useNotificationStore()
    const {unseenNotifications} = storeToRefs(useNotificationStore())

    return {
      unseenNotifications,
      setAllSeen: SetAllSeen
    }
  },
  methods: {
    handleBellClicked() {
      this.setAllSeen()
      this.$emit('bell-clicked')
    }
  }
})
</script>

<style lang="scss">
$amount-of-wiggling: 25deg;
$bell-size-mobile: 1.6rem;
$bell-size-desktop: 1.1rem;

.notification-mobile-icon {
  display: inline-block;
  align-items: center;

  @include breakpoint-up($lg) {
    display: flex;
    position: relative;

    @-moz-document url-prefix() {
      top: .07rem;
    }

    .count {
      top: unset;
      margin-left: .5rem;
    }
  }

  &:hover {
    text-decoration: none;
  }

  @include breakpoint-up($md) {
    .notification.icon--svg {
      font-size: $bell-size-desktop;
    }
  }

  @include breakpoint-down($md) {
    .count {
      right: -.375rem !important;
      top: -.8rem !important;
    }

    .mobile-icon.notification::before {
      margin-bottom: .3rem;
    }
  }
}

@include breakpoint-up($md) {
  .new-messages {
    position: relative;
    font-size: $bell-size-desktop !important;
    height: $bell-size-desktop;
    width: $bell-size-desktop;
    animation: wiggle 2s;
    animation-iteration-count: infinite;
  }
}

@include breakpoint-down($md) {
  .new-messages {
    font-size: $bell-size-mobile !important;
    height: $bell-size-mobile;
    width: $bell-size-mobile;
    animation: wiggle 2s;
    animation-iteration-count: infinite;
  }
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(0deg); }
  52% { transform: rotate($amount-of-wiggling); }
  54% { transform: rotate(0deg); }
  56% { transform: rotate(-$amount-of-wiggling); }
  58% { transform: rotate(0deg); }
  60% { transform: rotate($amount-of-wiggling); }
  62% { transform: rotate(0deg); }
  64% { transform: rotate(-$amount-of-wiggling); }
  66% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}
</style>
