import baseAxios from '@/services/axios_base'

export const useContactService = () => {
  const baseurl = useRuntimeConfig().public.CONTACT_URL
  const dev = useRuntimeConfig().public.NODE_ENV || 'development'

  const contact_axios = baseAxios.create({
    baseURL: baseurl
  })

  const postContactForm =  async function (data) {
    if (dev === 'development') {
      // eslint-disable-next-line no-console
      console.log('postContactForm', {...data})
      return true
    } else {
      const res = await contact_axios.post('', data).catch(() => {return null})
      return res ? res.data : null
    }
  }

  return {
    postContactForm
  }
}
