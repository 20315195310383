<template>
  <div class="skeleton__logged-in-header">
    <div
      class="skeleton__logged-in-header__language"
    >
      <Skeleton
        class="skeleton__logged-in-header__language__icon"
        :shape="'circle'"
      />

      <Skeleton class="skeleton__logged-in-header__language__text" />
      <Skeleton class="skeleton__logged-in-header__language__arrow" />
    </div>
    <div
      class="skeleton__logged-in-header__customer"
    >
      <Skeleton
        class="skeleton__logged-in-header__customer__icon"
        :shape="'circle'"
      />

      <Skeleton class="skeleton__logged-in-header__customer__text" />
      <Skeleton class="skeleton__logged-in-header__customer__arrow" />
    </div>
    <div
      class="skeleton__logged-in-header__help"
    >
      <Skeleton
        class="skeleton__logged-in-header__help__icon"
        :shape="'circle'"
      />

      <Skeleton class="skeleton__logged-in-header__help__text" />
    </div>
    <div
      class="skeleton__logged-in-header__notifications"
    >
      <Skeleton
        class="skeleton__logged-in-header__notifications__icon"
        :shape="'circle'"
      />
    </div>
    <div
      class="skeleton__logged-in-header__profile"
    >
      <Skeleton
        class="skeleton__logged-in-header__profile__icon"
        :shape="'circle'"
      />
      <Skeleton class="skeleton__logged-in-header__profile__text" />
      <Skeleton class="skeleton__logged-in-header__profile__arrow" />
    </div>
  </div>
</template>
<script>
import {Skeleton} from '@progress/kendo-vue-indicators'

export default {
  components: {
    Skeleton
  }
}
</script>
