<template>
  <div class="mega-menu-section">
    <div
      class="section-headline"
      :class="`${brandClass} ${!props.section?.externalUrl && !props.section?.localRoute ? 'no-links' : ''}`"
    >
      <a
        v-if="!props.section.localRoute"
        :href="props.section.externalUrl"
        :target="props.section.externalUrl ? '_blank' : '_self'"
      >{{ props.section.label }}</a>
      <nuxt-link
        v-else
        :to="localePath(props.section.localRoute)"
        @click="emit('close-menu')"
      >
        {{ props.section.label }}
      </nuxt-link>
    </div>
    <div
      class="section-row"
      v-for="(item, index) in props.section.Subsections"
      :key="index"
      :class="brandClassSub(item)"
    >
      <a
        v-if="!item.localRoute"
        :href="item.externalUrl"
        :target="item.externalUrl ? '_blank' : '_self'"
      >{{ item.label }}</a>
      <nuxt-link
        v-else
        :to="localePath(item.localRoute)"
        @click="emit('close-menu')"
      >
        {{ item.label }}
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
  section?: any
}>(), {
  section: () => {}
})

const emit = defineEmits(['close-menu'])

const brandClass = computed(() => {
  if (props.section?.brands) {
    let brandCodes = props.section?.brands.map(b => b.Code.toLowerCase()).sort()
    return ` brand-${brandCodes.join('')}`
  }
  return null
})

const brandClassSub = (item: any) => {
  if (item?.brands) {
    let brandCodes = item?.brands.map(b => b.Code.toLowerCase()).sort()
    return ` brand-${brandCodes.join('')}`
  }
  return null
}

</script>
<style lang="scss">
.mega-menu-section {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  page-break-inside: avoid;
  break-inside: avoid-column;

  a {
    color: $color__primary;
  }

  .section-headline {
    margin-bottom: .5rem;

    a {
      font-weight: $base-text-font-weight-semi-bold;
    }

    a:hover {
      color: $color__fst__primary__hover;
      cursor: pointer;
    }

    &.no-links {
      pointer-events: none;
    }
  }

  .section-row {
    font-weight: $base-text-font-weight-light;
    margin: .2rem 0;

    a:hover {
      color: $color__fst__primary__hover;
      cursor: pointer;
    }
  }
}
</style>
