<template>
  <div
    class="overlay"
    v-if="searchTermResult && searchTermResult.products"
  >
    <div
      v-if="searchTermResult.products.length"
      class="overlay__result"
    >
      <div class="overlay__header">
        <div
          class="h5"
        >
          <span v-tv:searchResults="'AmountOfProducts'" />: <span
            v-text="searchTermResult?.count?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')"
          />
        </div>
        <NavigationClose
          class="close"
          @click="closeOverlay()"
        />
      </div>
      <SearchOverlayResults
        :products="mappedProducts"
        :l2categories="l2categories"
        :categories="categories"
        :search-term="searchTerm"
        :is-any-search="isAnySearch"
        :search-filter="searchFilter"
        :is-inch-search="isInchSearch"
        @close-overlay="closeOverlay"
        @openBuyBox="openBuyBox"
        @clickItem="clickItem"
      />
    </div>
    <div
      v-else
      class="overlay__noProducts"
    >
      <div
        class="h5"
        v-tv:searchResults="'NoProducts'"
      />
    </div>
  </div>
</template>
<script>
import SearchOverlayResults from '~/components/search/SearchOverlayResults.vue'
import NavigationClose from '~/assets/icons/navigation_close.svg?component'
import {getMappedProducts} from '~/assets/js/utils/product_attributes_mapping'

export default {
  components: {
    NavigationClose,
    SearchOverlayResults
  },
  props: {
    searchTermResult: {
      type: Object,
      default () {
        return {}
      }
    },
    l2categories: {
      type: Array,
      default () {
        return []
      }
    },
    categories: {
      type: Array,
      default () {
        return []
      }
    },
    searchTerm: {
      type: String,
      default() {
        return ''
      }
    },
    searchFilter: {
      type: Array,
      default () {
        return []
      }
    },
    isInchSearch: {
      type: Boolean,
      default() {
        return false
      }
    },
    isAnySearch: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data() {
    return {
      topResults: []
    }
  },
  methods: {
    closeOverlay() {
      this.$emit('close-overlay')
    },
    openBuyBox(product) {
      this.$emit('openBuyBox', product)
    },
    clickItem(sku, rank) {
      this.$emit('clickItem', sku, rank)
    }
  },
  computed: {
    mappedProducts() {
      return getMappedProducts(this.searchTermResult)
    }
  }
}
</script>

<style lang="scss">
.overlay {
  background-color: $color__content--background__secondary;
  padding: $default-margin-padding-width $default-margin-padding-2x $default-margin-padding-width $default-margin-padding-2x;
  box-shadow: 0 .625rem 1.25rem .3125rem $color--nav__main--background-33pct;
  border: .125rem solid $color--input--focus;
  max-height: calc(100vh - 13rem);
  overflow-y: auto;

  @include breakpoint-up($lg) {
    width: 64rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    .close.icon--svg {
      position: relative;
      top: -.5rem;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  .h5 {
    margin-bottom: $default-margin-padding-width;
  }
}
</style>
