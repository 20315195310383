<template>
  <div>
    <div v-if="isReady">
      <client-only>
        <div
          class="nav__meta--mobile"
          v-if="
            loggedIn &&
              availableCompanies !== 0 &&
              currentCustomer.customerUuid !== 'default'
          "
        >
          <div class="nav__meta__container">
            <div
              v-if="availableCompanies === 1"
              class="customerLabel"
            >
              <span v-tv="currentCustomer.customerLabel" />
            </div>
            <DropDown
              v-else
              :data-items="customers"
              :text-field="'customerLabel'"
              :data-item-key="'customerUuid'"
              :value="currentCustomer"
              :filterable="true"
              @filterchange="onCustomerFilterChange"
              @change="changeCompany"
              :loading="customerLoading"
              :skip-client-filter="true"
              :filter="customerFilter"
              :key="rerender"
            />
          </div>
        </div>
      </client-only>
      <div class="pure-u-1 nav__meta">
        <div class="nav__meta__container nav__meta__container__language">
          <client-only>
            <DropDown
              class="dropdown--with-icon"
              :data-items="langSwitch"
              :value-render="iconDropdownValueRenderer"
              :text-field="'label'"
              :data-item-key="'value'"
              :value="langSwitch.find(p => p.value === $i18n.locale)"
              @change="changeLocale"
              id="languageswitch"
            >
              <template #iconDropdownTemplate="{ props }">
                <iconDropdownValueRenderer
                  :value="props.value ? props.value.label : ''"
                >
                  <Translate />
                </iconDropdownValueRenderer>
              </template>
            </DropDown>
          </client-only>
        </div>
        <div
          class="nav__meta__container nav__meta__container__region"
          v-show="!loggedIn && isReady"
        >
          <client-only>
            <DropDown
              class="dropdown--with-icon"
              :data-items="regionSwitch"
              :value-render="iconDropdownValueRenderer"
              :value="regionValue"
              :text-field="'label'"
              :data-item-key="'value'"
              @change="changeRegion"
            >
              <template #iconDropdownTemplate="{ props }">
                <iconDropdownValueRenderer
                  :value="props.value ? props.value.label : ''"
                >
                  <MapsLocationPin />
                </iconDropdownValueRenderer>
              </template>
            </DropDown>
          </client-only>
        </div>
        <div
          class="nav__meta__container nav__meta__container__company"
          v-if="
            loggedIn &&
              availableCompanies !== 0 &&
              currentCustomer.customerUuid !== 'default'
          "
        >
          <client-only>
            <div
              v-if="availableCompanies === 1"
              class="customerLabel"
            >
              <iconDropdownValueRenderer
                :value="currentCustomer.customerLabel"
                class="dropdown--with-icon"
              >
                <SocialPerson class="svg-social-person" />
              </iconDropdownValueRenderer>
            </div>
            <DropDown
              v-else
              class="dropdown--with-icon"
              :data-items="customers"
              :text-field="'customerLabel'"
              :data-item-key="'customerUuid'"
              :value="currentCustomer"
              :filterable="true"
              :value-render="iconDropdownValueRenderer"
              @filterchange="onCustomerFilterChange"
              @change="handleCompanyChange"
              @close="handleCompanyChange"
              :filter="customerFilter"
            >
              <template #iconDropdownTemplate="{ props }">
                <iconDropdownValueRenderer :value="props.value.customerLabel">
                  <SocialPerson class="svg-social-person" />
                </iconDropdownValueRenderer>
              </template>
            </DropDown>
          </client-only>
        </div>
        <div class="nav__meta__container nav__meta__container--icon-only">
          <nuxt-link
            class="link--icon-only"
            :to="localePath('/guides')"
          >
            <ActionHelpOutline
              class="svg-icon-help"
              :title="$tv('services.questionIconTooltip', 'header')"
            />
            <span>{{ $tv('services.questionLabel', 'header') }}</span>
          </nuxt-link>
        </div>
        <!--TODO: Implement Wishlist -->
        <!-- <div class="nav__meta__container nav__meta__container--icon-only">
          <nuxt-link
            class="link--icon-only"
            to="#"
          >
            <span
              :title="$tv('services.starIconTooltip', 'header')"
            >
              <ToggleStarOutline />
            </span>
          </nuxt-link>
        </div> -->
        <div
          class="nav__meta__container nav__meta__container--icon-only"
          v-show="loggedIn && runAtleastOnce"
        >
          <div
            class="nav__meta__container__notifications"
            :class="{'has-notification-label': getNotificationsLabelText != null}"
          >
            <NotificationBell
              @bell-clicked="tooltip = !tooltip"
              :unread-notifications-length="unreadNotifications.length"
            />
            <span
              v-show="getNotificationsLabelText"
              class="display-lg clickable"
              @click="tooltip = !tooltip"
              v-text="getNotificationsLabelText"
            />
            <div
              class="nav__meta__container--tooltip"
              v-show="unreadNotifications.length > 0"
            >
              <NotificationTT
                class="tooltip"
                :show-tool-tip="tooltip"
                :has-padding="false"
                tool-tip-position="bottom"
                arrow-position-vertically="top"
                arrow-position-horizontally="right"
              >
                <div class="nav__meta__container--tooltip__header">
                  <span>
                    {{ $tv('notifications', 'notifications') }}
                  </span>
                  <a
                    href="javascript:void(0)"
                    @click="tooltip = false"
                  >
                    <NavigationClose />
                  </a>
                </div>
                <NotificationList
                  :notifications="unreadNotifications"
                  :notifications-hidden="notificationsHidden"
                />
              </NotificationTT>
            </div>
          </div>
        </div>
        <div
          class="nav__meta__container nav__meta__container--icon-only"
          v-show="loggedIn && !runAtleastOnce && availableCompanies !== 0"
        >
          <div>
            <!-- <waiting-indicator /> -->
          </div>
        </div>
        <div
          class="nav__meta__container nav__meta__container__login"
          v-show="!loggedIn && isReady"
        >
          <a
            class="link--with-icon"
            href="#"
            @click.prevent="login"
            rel="nofollow"
          >
            <SocialPerson class="svg-social-person link__icon" />
            <span>{{ $tv('Login') }}</span>
          </a>
        </div>
        <div
          class="nav__meta__container nav__meta__container__login"
          v-if="loggedIn && isReady"
        >
          <DropDown
            class="dropdown--with-icon"
            :data-items="getProfileMenu"
            :value-render="iconDropdownValueRenderer"
            :value="''"
            :text-field="'label'"
            @change="serviceMenu"
          >
            <template #iconDropdownTemplate>
              <iconDropdownValueRenderer :value="getUserName">
                <SocialPerson class="svg-social-person" />
              </iconDropdownValueRenderer>
            </template>
          </DropDown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DropDown from '~/components/input-components/DropDown'
import IconDropdownValueRender from './IconDropdownValueRender.vue'
import {useImportantMessagesService} from '@/composables/important_messages_service'
import NotificationBell from '../notifications/NotificationBell'
import ToolTip from '@/components/ToolTip.vue'
import {mapActions} from 'pinia'
import NotificationList from '~/components/notifications/NotificationList.vue'
import {useCustomerService} from '@/composables/customer_service'
import {filterBy} from '@progress/kendo-data-query'
import {MAX_CUSTOMERS, USER_RIGHTS_MAPPING, ADDRESS_TYPE} from '~/assets/js/constants'
import Translate from '~/assets/icons/action_translate.svg?component'
import MapsLocationPin from '~/assets/icons/maps_location_pin.svg?component'
import SocialPerson from '~/assets/icons/social_person.svg?component'
import ActionHelpOutline from '~/assets/icons/action_help_outline.svg?component'
import NavigationClose from '~/assets/icons/navigation_close.svg?component'
import {isArrowKey, isESCKey} from '~/assets/js/check_for_key_helper.js'
import {useNotificationStore} from '@/store/notification'
import {useUserStore} from '@/store/user'
import {useCartStore} from '@/store/cart'
import {usePlantStore} from '@/store/plants'
import {useCustomerSettingsStore} from '@/store/customer-settings'
import {useImportantMessagesStore} from '@/store/important-messages'
//import {useAuth} from '@/composables/auth'
import {storeToRefs} from 'pinia'
import {fetchUser} from '~/assets/js/authentication/azureb2cNew'
import {getRegionForSalesArea} from '~/assets/js/utils/region_service'

export default {
  components: {
    iconDropdownValueRenderer: IconDropdownValueRender,
    NotificationBell,
    'NotificationTT': ToolTip,
    NotificationList,
    DropDown,
    Translate,
    MapsLocationPin,
    SocialPerson,
    ActionHelpOutline,
    NavigationClose
  },
  props: {
    isReady: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  setup() {
    const {getImportantMessages} = useImportantMessagesService()
    const {getFilteredCustomerList} = useCustomerService()

    const {unreadNotifications, runAtleastOnce} = storeToRefs(useNotificationStore())
    const {selectedCustomer, companies, availableCompanies, accessToken, name} = storeToRefs(useUserStore())

    return {
      getFilteredCustomerList,
      getImportantMessages,
      unreadNotifications,
      runAtleastOnce,
      selectedCustomer,
      companies,
      availableCompanies,
      accessToken,
      name
    }
  },
  fetchOnServer: false,
  async mounted() {
    if (this.loggedIn) {
      if (
        this.selectedCustomer &&
        this.selectedCustomer.customerUuid !== this.currentCustomer.customerUuid
      ) {
        this.currentCustomer.customerUuid = this.selectedCustomer.customerUuid
        this.currentCustomer.customerLabel = this.selectedCustomer.customerLabel
      }
    }
    await this.fetchImportantMessages()
    this.init_store_client()
  },
  data: function() {
    return {
      iconDropdownValueRenderer: 'iconDropdownTemplate',
      currentCustomer: {
        customerUuid: 'default',
        customerLabel: this.$tv('loading...')
      },
      languagesData: [],
      tooltip: false,
      notificationsHidden: {},
      customerLoading: false,
      customers: [],
      requestTimeout: null,
      filter: null,
      isCustomerSet: true,
      customerFilter: null,
      eventTimeout: null,
      rerender: 0
    }
  },
  watch: {
    selectedCustomer(val) {
      if (val && val.customerUuid !== this.currentCustomer.customerUuid) {
        this.currentCustomer.customerUuid = val.customerUuid
        this.currentCustomer.customerLabel = val.customerLabel
      }
    },
    '$i18n.locale': {
      immediate: true,
      async handler() {
        this.languagesData = this.$i18n?.locales
      }
    },
    'companies': {
      immediate: true,
      handler(val) {
        this.customers = val ? val.slice(0) : []
        this.rerender++
      }
    }
  },
  computed: {
    getNotificationsLabelText() {
      if (this.unreadNotifications.length === 1) {
        return this.$tv('notification', 'notifications')
      } else if (this.unreadNotifications.length > 1) {
        return this.$tv('notifications', 'notifications')
      }
      return null
    },
    getProfileMenu() {
      let profileMenuArray = [
        {
          label: this.$tv('profile.profile', 'header'),
          localRoute: '/profile'
        },
        {
          label: this.$tv('profile.logout', 'header'),
          logoutButton: true
        }
      ]

      if (this.selectedCustomer?.customerUuid !== 'default') {
        profileMenuArray.splice(1, 0, {
          label: this.$tv('profile.companyManagement', 'header'),
          localRoute:
            '/management/' +
            this.$tv(
              'management-companyinformation',
              'pages',
              'companyinformation'
            )
        })
      }

      if (this.hasRight(USER_RIGHTS_MAPPING.USER_VIEW)) {
        profileMenuArray.splice(2, 0, {
          label: this.$tv('profile.userManagement', 'header'),
          localRoute:
            '/management/' + this.$tv('management-users', 'pages', 'users')
        })
      }
      return profileMenuArray
    },
    regionSwitch() {
      return (
        (this.$i18n.locale &&
          this.$config.public.regions?.map(l => ({
            label: this.$tv(l.RegionName, 'regions'),
            value: l.RegionShortName
          }))) ||
        []
      )
    },
    regionValue() {
      return this.regionSwitch.find(
        p => p.value === this.$globalization.__currentRegion
      )
    },
    getUserName() {
      return this.name
    },
    langSwitch() {
      let languages = this.$i18n?.locales.map(l => ({
        label: this.$tv(l.code, 'locales'),
        value: l.code
      }))

      return languages
    },
    loggedIn() {
      return this.$auth.loggedIn
    }
  },
  methods: {
    ...mapActions(useNotificationStore, ['resetRunAtleastOnce', 'resetPollingTimer', 'startPolling', 'init_store_client']),
    ...mapActions(useCartStore, ['setSelectedAddress']),
    ...mapActions(usePlantStore, ['fetchAllPlants']),
    ...mapActions(useCustomerSettingsStore, ['fetchCustomerSettings']),
    ...mapActions(useUserStore, ['setSelectedCustomer']),
    ...mapActions(useImportantMessagesStore, ['fetchMessages']),
    async changeLocale(e) {
      this.$eventbus.emit('loading', true)
      await this.$i18n.setLocale(e.value.value)
      await this.$nextTick()
      this.$emit('reload-translations')
      this.fetchImportantMessages()
    },
    async changeRegion(e) {
      this.$globalization.switchRegion(e.value.value)
    },
    serviceMenu(e) {
      if (e.value?.logoutButton) {
        navigateTo({path: '/auth/logout'})
        return
      }

      if (e.value.localRoute) {
        this.$router.push(this.localePath(e.value.localRoute))
      } else {
        window.open(e.value.externalUrl, '_blank')
      }
    },
    login() {
      this.$emit('login')
    },
    handleCompanyChange(e) {
      clearTimeout(this.eventTimeout)
      this.eventTimeout = setTimeout(() => this.changeCompany(e), 50)
    },
    async changeCompany(e) {
      this.currentCustomer.customerUuid = e.value.customerUuid
      this.currentCustomer.customerLabel = e.value.customerLabel
      if (
        !isArrowKey(e) &&
        !isESCKey(e) &&
        this.selectedCustomer?.customerUuid !==
          this.currentCustomer.customerUuid
      ) {
        const newCustomer = this.customers.find(c => c.customerUuid === this.currentCustomer.customerUuid)
        this.resetRunAtleastOnce()
        this.resetPollingTimer()
        this.$emit('change-customer')
        await this.$nextTick()
        this.setSelectedAddress({address: null, type: ADDRESS_TYPE.Delivery})
        this.setSelectedAddress({address: null, type: ADDRESS_TYPE.Invoice})
        useUserStore().newCustomer = newCustomer.customerUuid
        const newRegion = getRegionForSalesArea(`${newCustomer?.salesOrg}-${newCustomer?.salesChannel}-${newCustomer?.distribution}`)
        if (this.$globalization.__currentRegion !== newRegion) {
          this.$globalization.switchRegion(newRegion, true)
        }
        const app = useNuxtApp()
        await fetchUser(app.$userManager, 'change_company')
        this.startPolling()
        this.fetchAllPlants(true)
        this.fetchCustomerSettings()
        this.onCustomerFilterChange({filter: null})
        this.$emit('change-customer')
      } else if (isESCKey(e)) {
        this.currentCustomer.customerUuid = this.selectedCustomer?.customerUuid
        this.currentCustomer.customerLabel = this.selectedCustomer?.customerLabel
      }
    },
    async fetchImportantMessages() {
      this.fetchMessages(
        await this.getImportantMessages(
          this.$i18n.locale,
          this.$globalization.getRegion()
        )
      )
    },
    async onCustomerFilterChange(event) {
      const filter = event.filter
      this.customerFilter = filter?.value ?? null
      this.customerLoading = true
      this.customers = [{customerLabel: this.$tv('loading...')}]
      await this.$nextTick()
      if (this.availableCompanies > MAX_CUSTOMERS) {
        if (this.requestTimeout) {
          clearTimeout(this.requestTimeout)
        }
        this.requestTimeout = setTimeout(async () => {
          this.filter = filter
          const newCustomerData = await this.getFilteredCustomerList(
            filter,
            MAX_CUSTOMERS
          )
          if (
            newCustomerData &&
            newCustomerData.value &&
            filter?.value === this.filter?.value
          ) {
            this.customers = newCustomerData.value.slice(0)
          }
        }, 200)
      } else {
        this.customers = filterBy(this.companies.slice(0), filter)
      }
      this.customerLoading = false
    }
  }
}
</script>
<style lang="scss">
.nav__meta__container__company {
  .customerLabel {
    align-items: center;
    margin: auto;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .25rem;
    overflow: hidden;

    .dropdown--with-icon {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: $base-text-font-weight-semi-bold;
    }
  }
}

.nav__meta__container__notifications {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .count {
    font-size: $base-text-font-size;
  }

  &.has-notification-label {
    padding-left: .925rem;
  }

  span.display-lg {
    margin-left: .2rem;
    font-size: $base-text-font-size;
  }
}

.nav .nav__meta .nav__meta__container__notifications .nav__meta__container--tooltip {
  top: .8125rem;
}

.nav .nav__meta .nav__meta__container__notifications.has-notification-label .nav__meta__container--tooltip .tool-tip__wrapper.arrow.arrow-right::after {
  @include breakpoint-up($lg) {
    right: 7.4rem;
  }
}

.display-lg {
  @include breakpoint-up($lg) {
    display: inline-block;
  }

  @include breakpoint-down($lg) {
    display: none;
  }
}
</style>
