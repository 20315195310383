<template>
  <div class="pageNavigation">
    <div class="pageNavigation__breadcrumb">
      <div
        v-for="(link, index) in breadcrumb"
        :key="index + '_' + changeKey"
      >
        <Crumb
          :link="link"
          :breadcrumb="breadcrumb"
          :index="index"
          :key="index + '_' + changeKey"
        />
      </div>
    </div>
    <client-only>
      <div class="pageNavigation__list">
        <div
          v-if="this.$auth.loggedIn"
          class="pageNavigation__list__linkContainer"
        >
          <div
            v-for="(link, index) in listLinks"
            :key="index"
          >
            <nuxt-link
              :to="localePath(link.url)"
              class="pageNavigation__list__link k-button k-button-md"
            >
              {{ link.label }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </client-only>
  </div>
</template>
<script>
import Crumb from './Crumb.vue'
import {useBreadcrumbStore} from '~/store/breadcrumb'
import {storeToRefs} from 'pinia'
export default defineComponent({
  components: {Crumb},
  props: {
    listLinks: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    breadcrumb() {
      return [{label: this.$tv('home', 'breadcrumbs'), path: this.localePath('/')}].concat(this.paths)
    }
  },
  setup() {
    const {paths} = storeToRefs(useBreadcrumbStore())
    return {paths}
  },
  data() {
    return {
      regions: this.$config.public.regions?.map(l => l.RegionShortName?.toLowerCase())?.join('|'),
      languages: this.$i18n?.locales?.map(l => l.code)?.join('|'),
      changeKey: 0
    }
  }
})
</script>
<style lang="scss">
.pageNavigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: .75rem;
  min-height: 2.5625rem;

  &__breadcrumb {
    margin-left: $default-margin-padding-2x;
    align-items: center;
    display: flex;

    &__link {
      a {
        color: $color__text__default;
      }

      font-weight: lighter;
      margin-right: .2rem;
    }
  }

  &__list {
    display: flex;

    &__linkContainer {
      display: flex;
      flex-direction: row;
    }

    &__link {
      z-index: 1;
      position: initial;
      margin-left: .2rem;
      background-color: $color__content--background__secondary;
      padding: .7813rem 1.375rem;
      color: $color__primary;
      font-size: $base-text-font-size;
      font-weight: $base-text-font-weight-semi-bold;
    }

    &__link:hover {
      background-color: $color--btn__secondary--hover;
      color: $color__white;
    }
  }

  @include breakpoint-down($md) {
    display: none;
  }
}
</style>
