import {useCmsService} from '@/composables/cms_service'

export const useImportantMessagesService = () => {
  const {getCmsEntityList} = useCmsService()

  const getImportantMessages = (lang, region) => {
    const today = new Date()
    today.setSeconds(0)
    today.setMilliseconds(0)
    return getCmsEntityList('important-messages', lang, 0, -1, 'Start', true, [{field: 'regions.RegionShortName', operator: 'eq', value: region}, {field: 'Start', operator: 'lte', value: today}, {field: 'End', operator: 'gte', value: today}])
  }

  return {getImportantMessages}
}
