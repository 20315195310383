<template>
  <span class="k-input">
    <em
      v-if="iconcss"
      class="dropdown__icon"
      :class="iconcss"
    />
    <em
      v-else
      class="dropdown__icon"
    >
      <slot />
    </em>
    <span>{{ value }}</span>
  </span>
</template>
<script>
export default {
  props: {
    iconcss: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      type: String,
      default() {
        return ''
      }
    }
  }
}
</script>
