<template>
  <div
    class="overlayResults"
    v-if="products && products.length"
  >
    <span
      v-if="isAnySearch"
      class="anysearch-warning"
    >
      <AlertWarning /> {{ $tv({messageKey: 'warningAnySearch', messageValues: {search: searchTerm} }, 'searchResults') }}
    </span>
    <div class="overlayResults__tableheader overlayResults__tile">
      <span class="img-block overlayResults__tile__image" />
      <span class="text-block overlayResults__tile__description" />
      <span class="overlayResults__tile__icon" />
    </div>
    <div
      v-for="(product, index) in products"
      :key="index"
      @click="closeOverlay"
    >
      <div
        @click="clickItem(product?.sku, index + 1)"
        class="overlayResults__tile"
      >
        <nuxt-link
          class="overlayResults-left"
          :to="getLocalePath(product)"
        >
          <div class="overlayResults__tile__image">
            <img
              :src="imageUrl(product, true)"
              :alt="product.sku"
            >
          </div>
          <div class="pure-u-3-4 overlayResults__tile__description">
            <div
              class="ellipsis"
              v-text="getProductText(product)"
            />
          </div>
        </nuxt-link>
        <div class="overlayResults-right">
          <div>
            <nuxt-link
              :to="getLocalePath(product)"
              class="pure-u-1-8"
            >
              <div class="overlayResults__tile__sku">
                <SKUContainer
                  :product="product"
                  :multiple-lines="true"
                  :show-values-in-row="true"
                />
              </div>
            </nuxt-link>
          </div>
          <div>
            <a
              href="javascript:void(0)"
              class="overlayResults__tile__icon"
              :class="{disabled: !isBuyable(product)}"
              @click.prevent="openBuyBox(product)"
            >
              <ActionShoppingCart
                class="svg-action-shopping-cart"
                :title="$tv('users.searchShoppingCartIconTooltip', 'management')"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="overlayResults__information">
      <div
        class="overlayResults__information--center"
        v-tv:searchResults="'ThereAreMoreProducts'"
      />
      <div @click="closeOverlay">
        <nuxt-link
          :to="resultPathWithCategory"
          class="k-button k-button-md k-button-solid k-button-solid-primary"
          v-tv:searchResults="'ShowAllResults'"
        />
      </div>
    </div>
    <div
      class="h5"
      v-tv:searchResults="'MatchingProductCategories'"
    />
    <div class="overlay2__designs">
      <div
        class="pure-u-1-2"
        v-for="(primaryL2Category, index) in l2categories"
        :key="index"
      >
        <nuxt-link
          :to="`${resultPath}&${primaryL2CategoryName}=${primaryL2Category.categoryName}`"
        >
          <div
            class="overlayResults__designs__tile"
            @click="closeOverlay"
          >
            <div
              class="overlayResults__designs__tile__image"
            >
              <img
                :src="imageUrl(primaryL2Category.categoryName)"
                :alt="primaryL2Category.categoryName"
              >
            </div>
            <div class="overlayResults__designs__tile__description">
              <span
                class="ellipsis"
                v-text="`${$tv(primaryL2Category?.categoryName, 'categories')} (${primaryL2Category?.hitCount?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')})`"
              />
            </div>
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>
<script>
import {CATEGORIES, NON_ATTRIBUTE_FILTERS} from '~/assets/js/constants'
import itemImageUrlMixin from '~/mixins/item_image_url'
import ActionShoppingCart from '~/assets/icons/action_shopping_cart_plus.svg?component'
import defaultCustomerMixin from '~/mixins/default_customer'
import categoryUrlMixin from '~/mixins/category_urls'
import {getEComText} from '~/assets/js/utils/product'
import SKUContainer from '~/components/SKUContainer'
import AlertWarning from '~/assets/icons/alert_warning.svg?component'

export default {
  components: {ActionShoppingCart, SKUContainer, AlertWarning},
  props: {
    products: {
      type: Array,
      default () {
        return []
      }
    },
    l2categories: {
      type: Array,
      default () {
        return []
      }
    },
    categories: {
      type: Array,
      default () {
        return []
      }
    },
    searchTerm: {
      type: String,
      default() {
        return ''
      }
    },
    searchFilter: {
      type: Array,
      default () {
        return []
      }
    },
    isInchSearch: {
      type: Boolean,
      default() {
        return false
      }
    },
    isAnySearch: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  mixins: [itemImageUrlMixin, defaultCustomerMixin, categoryUrlMixin],
  data() {
    return {
      rootCategoriesUrl: CATEGORIES.RootCategoriesUrl,
      primaryL2CategoryName: NON_ATTRIBUTE_FILTERS.PRIMARYL2CATEGORY
    }
  },
  computed: {
    resultPath() {
      const path = this.localePath({name: 'searchResults'})
      return path + '?query=' + encodeURIComponent(this.searchTerm)
    },
    resultPathWithCategory() {
      if (this.products && this.products.length === 1) {
        return this.getLocalePath(this.products[0])
      }
      if (this.searchFilter.length === 0) {
        return this.resultPath
      }

      let catFilter = ''
      this.searchFilter.forEach(filter => {
        if (catFilter === '') {
          catFilter = `ProductDesign=${filter}`
        } else {
          catFilter += `%24${filter}`
        }
      })

      return `${this.resultPath}&${catFilter}`
    }
  },
  methods: {
    closeOverlay() {
      this.$emit('close-overlay')
    },
    isBuyable(product) {
      return (((product?.requestable ?? false) || (product?.buyable ?? false) || this.isDefaultCustomer) && this.$auth.loggedIn) || !this.$auth.loggedIn
    },
    openBuyBox(product) {
      if (this.isBuyable(product)) {
        this.$emit('openBuyBox', product)
      }
    },
    getLocalePath(product) {
      return this.localePath('/categories/' + this.getCategoryUrlFromTechnicalName(product.productDesign, this.$i18n.locale) + '/products' + '/' + product.sku, this.$i18n.locale)
    },
    getProductText(product) {
      return getEComText(this.$tv, product) + ' | ' + this.getQuantityDisplay(product?.variant?.amount, product?.variant?.unit ?? '')
    },
    getQuantityDisplay(quantity, unit) {
      const formattedQuantity = quantity.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')
      return this.$tv({messageKey: 'quantityAndUnit', messageValues: {quantity: formattedQuantity, unit: this.$tv(unit, 'packageUnits')}})
    },
    clickItem: function(sku, rank) {
      this.$emit('clickItem', sku, rank)
    }
  }
}
</script>

<style lang="scss">
.overlayResults {
  a {
    text-decoration: none;
  }

  &__tableheader.overlayResults__tile {
    display: none;
    box-shadow: none;
    background-color: transparent;
    color: $color__text__default;
    min-height: unset;

    .img-block {
      width: 3rem;
    }

    .text-block {
      width: 75%;
    }

    @include breakpoint-up($md) {
      display: flex;
    }

  }

  .overlayResults__tile  {
    .overlayResults-left  {
      width: 100%;

      > a {
        display: flex;
        align-items: center;
        color: $color__text__default;
      }

      .overlayResults__tile__image {
        align-items: flex-end;

        img {
          width: 3rem;
        }
      }
    }

    .overlayResults--right {
      width: 50%;
      justify-content: flex-end;
    }
  }

  &__tile {
    background-color: $color__white;
    margin-bottom: .125rem;
    display: flex;
    justify-content: space-between;
    color: $color__text__default;
    box-shadow: 0 .1875rem .375rem $color--card__shadow;
    min-height: 3.125rem;

    > div,
    > a {
      display: flex;
      align-items: center;
      color: $color__text__default;
    }

    &__description {
      width: 100%;
      overflow: auto;
      font-size: $base-text-font-size;

      > div {
        font-weight: $base-text-font-weight-light;
        padding: 0 $default-margin-padding-width;
      }

      .ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: $base-text-font-size;

        @include breakpoint-down($md) {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }
    }

    &__sku {
      color: $color__primary;
      width: 100%;
      font-weight: $base-text-font-weight-bold;
      padding: $default-margin-padding-width-small $default-margin-padding-width;
      display: flex;
      flex-direction: row;
      font-size: $base-text-font-size;

      @include breakpoint-down($md) {
        display: none;
      }
    }

    .overlayResults__tile__image {
      align-items: flex-end;

      img {
        width: 3rem;
      }
    }

    &__icon {
      padding: 0 .625rem 0 1.875rem;

      .icon--svg.svg-action-shopping-cart {
        font-size: 1.8rem;
      }

      .icon--svg {
        color: $color__primary;
      }

      &.disabled {
        pointer-events: none;

        .icon--svg {
          color: $color__page--background--opacity;
        }
      }
    }
  }

  &__information {
    display: flex;
    font-weight: $base-text-font-weight-light;
    justify-content: space-between;
    margin-bottom: $default-margin-padding-width;
    margin-top: $default-margin-padding-width;

    @include breakpoint-down($md) {
      display: inline;

      div {
        margin-bottom: $default-margin-padding-width;
      }
    }

    &--center {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__designs {
    display: flex;
    flex-wrap: wrap;

    &__tile {
      background-color: $color__white;
      margin: 0 0 .125rem;
      display: flex;
      color: $color__primary;
      font-weight: $base-text-font-weight-bold;
      box-shadow: 0 .1875rem .375rem $color--card__shadow;
      min-height: 3.125rem;

      > div {
        display: flex;
        align-items: center;
      }

      .overlayResults__designs__tile__image {
        align-items: flex-end;

        img {
          width: 3rem;
        }
      }

      &__description {
        padding: 0 $default-margin-padding-width;
        width: calc(100% - 3rem - #{$default-margin-padding-2x});

        @include breakpoint-up($lg) {
          padding-left: 1.6875rem;
        }
      }
    }
  }

  .pure-u-1-2:nth-child(odd) {
    .overlayResults__designs__tile {
      margin-right: $default-margin-padding-width;
    }
  }

  .h5 {
    margin-bottom: $default-margin-padding-width;
  }
}
</style>
