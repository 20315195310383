<template>
  <div
    class="mobile-menu"
    :class="{'open': props.open}"
  >
    <MenuPoint
      v-if="menuHierachy.length > 0"
      class="menu-point--back"
      :key="index"
      :section="currentLevel"
      :is-back="true"
      @top-level="goToTopLevel"
    />
    <MenuPoint
      v-for="(point, index) in currentMenuItems"
      :key="index"
      :class="{ 'menu-point-sublevel': currentLevel !== null }"
      :section="point"
      :is-back="false"
      @sub-level="goToSubLevel(point)"
      @top-level="goToTopLevel"
      @close-menu="emit('close-menu')"
    />
    <MenuPoint
      v-if="menuHierachy.length === 0"
      class="language-selection"
      :section="language"
      :is-back="false"
      @sub-level="goToSubLevel(language)"
    />
    <MenuPoint
      v-if="menuHierachy.length === 0 && !$auth.loggedIn"
      class="region-selection"
      :section="region"
      :is-back="false"
      @sub-level="goToSubLevel(region)"
    />
  </div>
</template>

<script setup lang="ts">
import MenuPoint from '~/components/navigation/MenuPoint.vue'
import useI18nExtended from '~/composables/usei18nExtended'

export interface Props {
  menuPoints?: any,
  open?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  menuPoints: () => [],
  open: false
})

const app = useNuxtApp()
const i18n = useI18nExtended()

const menuHierachy = ref([])
const currentLevel = ref(null)

const emit = defineEmits(['reload-translations', 'close-menu'])

const languageSubmenu = computed(() => {
  return (
    i18n?.locales?.value?.map(l => ({
      label: i18n.tv(l.code, 'locales'),
      value: l.code,
      function: changeLocale
    })) ?? []
  )
})

const language = computed(() => {
  return {
    preLabel: i18n.tv('LANGUAGE'),
    label: i18n.tv(i18n.locale.value, 'locales'),
    Subsections: languageSubmenu.value
  }
})

const region = computed(() => {
  return {
    preLabel: i18n.tv('REGION'),
    label: i18n.tv(app.$config.public.regions.find(r => r.RegionShortName === app.$globalization.__currentRegion)?.RegionName, 'regions'),
    Subsections: app.$config.public.regions?.map(r => ({
      label: i18n.tv(r.RegionName, 'regions'),
      value: r.RegionShortName,
      function: changeRegion
    }))
  }
})

const changeLocale = async(locale) => {
  app.$eventbus.emit('loading', true)
  await i18n.setLocale(locale)
  await nextTick()
  emit('reload-translations')
}

const changeRegion = async(e) => {
  app.$globalization.switchRegion(e)
}

const goToSubLevel = (point) => {
  menuHierachy.value.push(currentLevel.value)
  currentLevel.value = point
}

const goToTopLevel = () => {
  currentLevel.value = menuHierachy.value.pop()
}

const currentMenuItems = computed(() => {
  return currentLevel.value !== null ? currentLevel.value?.Subsections : props.menuPoints
})

watch(
  () => props.open, () => {
    currentLevel.value = null
    menuHierachy.value = []
  }
)

</script>

<style lang="scss">
.mobile-menu {
  background-color: $color__white;

  .menu-point--back {
    position: fixed;
    z-index: 1;
    border-bottom: .2rem solid $color__fst__light__gray;
    background-color: $color__white;
  }

  .menu-point-sublevel {
    width: calc(100% - 5.4rem);
    margin-left: 2.4rem;
  }

  .language-selection {
    margin-top: 3rem;
  }

  .menu-point-wrapper:nth-of-type(1) {
    padding-top: 3rem;
  }

  &:has(.menu-point--back) {
    .menu-point-wrapper:nth-of-type(2) {
      padding-top: 6.25rem;
    }
  }
}
</style>
