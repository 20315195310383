import {defineStore} from 'pinia'
import {useUserStore} from './user'
import {useNotificationService} from '@/composables/notification_service'

const hashObject = async (data) => {
  const encoder = new TextEncoder()
  const dataEncoded = encoder.encode(data)
  const arrayBuffer = await crypto.subtle.digest('SHA-256', dataEncoded)
  return btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)))
}

let failedPolls = 0
let pollingTimer = null

export const useNotificationStore = defineStore('NotificationModule', {
  state: () => ({
    notifications: [],
    runAtleastOnce: false
  }),
  getters: {
    allNotifications() {
      return this.notifications
    },
    unreadNotifications() {
      return this.notifications?.filter((el) => !(el.isRead ?? false))
    },
    unseenNotifications() {
      return this.notifications?.filter((el) => !el.seen && !el.isRead).length > 0
    },
    async hash() {
      return await hashObject(JSON.stringify(this.notifications || ''))
    }
  },
  actions: {
    resetRunAtleastOnce() {
      this.runAtleastOnce = false
    },
    resetPollingTimer() {
      clearTimeout(pollingTimer)
    },
    async fetchNotifications() {
      let response
      try {
        const userStore = useUserStore()
        const currentCustomerUuid = userStore.loadedCustomerUuid
        if (userStore.accessToken !== '' && userStore.loggedIn && currentCustomerUuid !== '' && currentCustomerUuid !== 'default') {
          response = await useNotificationService().pollMessages()
          this.runAtleastOnce = true
        } else {
          return true
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        return false
      }
      const newArray = Array.isArray(response) ? response.map((newNotification) => ({...this.allNotifications?.find( (n) => n.uuid === newNotification.uuid), ...newNotification})) : []
      let newHash = await hashObject(JSON.stringify(newArray))
      if (newHash !== this.hash) {
        this.notifications = newArray
      }
      return true
    },
    async SetRead(payload) {
      const currentState = JSON.parse(JSON.stringify(this.allNotifications))
      // Change single entry to runtime to avoid reload
      const index = currentState.findIndex((notification) => notification.notificationUuid === payload.uuid)
      if (index > -1) {
        currentState[index].isRead = payload.isRead
        this.notifications = currentState
      }
    },
    async startPolling() {
      const TIMEOUT_MS = useRuntimeConfig().public.NOTIFICATIONS_POLLING_TIME || 30000
      const pollingTime = !this.runAtleastOnce ? 200 : TIMEOUT_MS
      pollingTimer = setTimeout(async () => {
        let successful = await this.fetchNotifications()
        if (!successful) {
          failedPolls++
        }

        if (failedPolls > 3) {
          // eslint-disable-next-line no-console
          console.warn('stopped notification polling due to multiple failed requests in a row')
          return
        }

        this.startPolling()
      }, pollingTime)
    },
    async init_store_client() {
      if (!process.client) {return}
      await this.fetchNotifications()

      if (pollingTimer) {
        clearTimeout(pollingTimer)
      }
      this.startPolling()
    },
    async SetAllSeen() {
      const newNotifications = [...this.notifications]
      newNotifications.forEach((notification) => notification.seen = true)
      this.notifications = newNotifications
    }
  }
})
