<template>
  <div class="notification-single">
    <nuxt-link
      class="notification-body"
      :to="localePath($tv(notification.link, 'notifications'))"
    >
      <div class="notification-link">
        {{ getTranslatedMessageKey(notification) }}
      </div>
      <div
        class="notification-date"
        v-text="getDate"
      />
    </nuxt-link>
    <kbutton
      theme-color="light"
      @click="$emit('markAsRead', {uuid: notification.notificationUuid, index: index})"
    >
      <NavigationClose />
    </kbutton>
  </div>
</template>

<script>
import NavigationClose from '~/assets/icons/navigation_close.svg?component'
import {Button} from '@progress/kendo-vue-buttons'

export default {
  components: {NavigationClose, kbutton: Button},
  props: {
    notification: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    getDate: function() {
      const options = {timeStyle: 'short', dateStyle: 'full'}
      const dateObj = new Date(this.notification.createdOn)
      return dateObj.toLocaleString(this.$i18n.locale || 'de', options)
    }
  },
  methods: {
    getTranslatedMessageKey(message) {
      if (message.messageKey.includes(';')) {
        const messageKeys = message.messageKey.split(';')
        let returnString = ''
        messageKeys.forEach((key) => {
          const translationObject = {
            messageValues: message.messageValues,
            messageKey: key
          }
          returnString += this.$tv(translationObject, 'notifications') + '\n'
        })
        return returnString
      }
      return this.$tv(message, 'notifications')
    }
  }
}
</script>

<style lang="scss">
.notification-single {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 20rem;
  padding: $default-margin-padding-width;

  @include breakpoint-down($md) {
    padding: $default-margin-padding-width 0 $default-margin-padding-width 0;
    width: 100%;
  }

  .notification-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  a {
    flex-grow: 1;
    height: 100%;
  }

  a:hover {
    text-decoration: none;
  }

  .k-button {
    min-width: unset;
    min-height: unset;
    padding: .5rem;
    flex-grow: 0;
    background-color: $color__white;
  }

  .notification-date {
    color: $color__black;
    font-weight: lighter;
    font-size: .8rem;
    margin-top: $default-margin-padding-width;
  }

  .notification-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $color__primary;
  }
}
</style>
