
<template>
  <div id="NotificationList">
    <div
      v-for="(notification, index) in notifications"
      :key="index"
      class="notification-wrapper"
      :class="{'faded-out': notificationsHidden[index]}"
    >
      <NotificationSingle
        :notification="notification"
        :index="index"
        @markAsRead="handleMarkAsRead"
      />
    </div>
    <div
      class="no-unread-text notification-wrapper"
      v-if="notifications.length === 0"
    >
      <div class="notification-single">
        <div class="notifaction-body">
          {{ $tv('noUnreadNotifications', 'notifications') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationSingle from '~/components/notifications/NotificationSingle.vue'
import {storeToRefs} from 'pinia'
import {useUserStore} from '@/store/user'
import {useNotificationStore} from '@/store/notification'
import {useNotificationService} from '@/composables/notification_service'

export default {
  components: {
    NotificationSingle
  },
  props: {
    notifications: {
      type: Array,
      default() {
        return []
      }
    },
    notificationsHidden: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  setup() {
    const {companyUuid, userUuid} = storeToRefs(useUserStore())
    const {SetRead} = useNotificationStore()
    const {markAsRead} = useNotificationService()
    return {
      companyUuid,
      userUuid,
      setRead: SetRead,
      markAsRead
    }
  },
  methods: {
    handleMarkAsRead(payload) {
      if (typeof payload !== 'object') {
        return
      }

      // TODO figure out how to animate the entries that getting markedAsRead
      this.$emit('hide-notification', payload.index)

      // setTimeout(() => {
      this.markAsRead(payload.uuid, this.userUuid, this.companyUuid)
      this.setRead({uuid: payload.uuid, isRead: true})
      // }, 1000)
    }
  }
}
</script>

<style lang="scss">
[id='NotificationList'] {
  .no-unread-text {
    font-weight: lighter;
  }

  @include breakpoint-up($md) {
    max-height: 20rem;
    overflow-y: auto;
  }

  .notification-wrapper {
    position: relative;
    opacity: 1;
    max-height: 100vh;
    transition: opacity $transition-time__default ease-in-out, max-height $transition-time__default * 3 ease-in-out;
    border-bottom: .1em solid $color__content--background__secondary;
  }

  .notification-wrapper:last-child {
    border-bottom: 0;
  }

  .faded-out {
    max-height: 0;
    opacity: 0;
  }
}
</style>
